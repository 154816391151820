.sidebar {
  position: fixed;
  bottom: 0;
  z-index: 99;
  height: calc(100vh - 65px);
  width: 200px;
  box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  padding-top: 0;
  opacity: 1;
  font-weight: 400;
  @media screen and (max-width: 1500px) {
    width: 220px;
    padding-top: 0;
  }
  
  .theme-dark .sidebar, .theme-light .sidebar {
    background: $color-accent;
}
  a {
    display: block;
    background-color: $color-accent;
    text-decoration: none;
  }

  &.sidebar--show {
    display: block;

    .sidebar__back {
      display: block;
    }
  }
}

.sidebar__back {
  height: 100%;
  width: 100vw;
  position: absolute;
  display: none;
  background: transparent;
  border: none;
}

.sidebar__link-active {
  .sidebar__link {
    background: $color-accent-dark !important;
    &:before {
      opacity: 1;
    }
  }
  }

.sidebar__scroll {
  width: 200px;

  @media screen and (max-width: 1500px) {
    width: 220px;
  }

  & > div {
    height: 100%;

    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $color-accent;
    }
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;

    }

    &.scrollbar-track-x {
      display: none !important;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
}

.sidebar__content {
  height: 100%;
  overflow: auto;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
}

.sidebar__help-content  {
  font-size: 100%;
  font-family: "Source Sans Pro";
  display: flex;
  align-self: flex-end;
  width: 100%;
  margin-top: 15px;
  margin-bottom: -1.5rem;

  ul {
    margin: auto;
    text-align: center;
    list-style: none;
    padding: 0px;
  }

  a {
    text-decoration: none;
    color: #fff;
    font-weight: 900;
  }

  span {
    color: #fff;
    font-weight: 900;
    font-size: 11px;
  }
}

.sidebar__block {
  padding: 0px 0px;
  padding-bottom: 0;
  background: #43425b;
  &:last-child {
    border: none;
  }
}
 div.sidebar__wrapper.sidebar__wrapper--desktop > div > ul {
  background: $color-accent;
 }
 .scrollbar-none::-webkit-scrollbar {
  display: none;
}
.sidebar__link {
  height: 55px;
  width: 200px;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 21px 20px;
  overflow: hidden;
  background: $color-accent;
  border: none;
  color: #fff;

  @media screen and (max-width: 1500px) {
    width: 220px;
    height: 52px;
  }
  @media screen and (max-width: 600px) {
    height: 46px;
  }
  @media screen and (max-width: 330px) {
    height: 24px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background: $color-sidebar-link-left;
    opacity: 0;

  }

  &:hover {
    background: $color-accent-dark !important;
  }

  p {
    position: relative;
    width: 200px;
    top: 50%;
    transform: translateY(-50%);

  }

  &:hover {
    text-decoration: none;

    &:before {
      opacity: 1;
    }
  }
}

.sidebar__link-title {
  margin: 0;
  font-size: 13px;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  color: #fff !important;

  @media screen and (max-width: 1500px) {
    font-size: 13px;
  }
}

.sidebar__link-icon {
  font-size: 16px;
  line-height: 13px;
  color: #fff;
  font-weight: 900;
  margin-right: 10px;
  @media screen and (max-width: 1500px) {
    font-size: 15px;
    line-height: 8px;
  }

}

.sidebar__submenu {
  transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
  padding: 0px 0;
  background-color: #43425dfc !important;

  & .sidebar__submenu {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;

  }
  // background-color: $color-accent;
  
  .sidebar__link {
    padding-left: 30px;
  background-color: #1bc1aa;
  }
}
.sidebar__submenu-wrap {
  background-color: #1bc1aa;
}
.rtl-support {

  .sidebar__category-icon {
    transform: rotate(180deg);
  }
}

.sidebar__category-icon {
  position: absolute;
  font-size: 11px;
  font-weight: 900;
  line-height: 14px;
  opacity: 1;
  transition: opacity 0.5s 0.2s, transform 0.3s;
  color: #fff;
  margin-right:'10px';


}

.sidebar__link-badge {
  width: 26px;
  height: 14px;
  background-color: $color-red;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;


  span {
    position: absolute;
    top: 3px;
    width: 26px;
    text-align: center;

  }
}

.sidebar__wrapper--desktop {
  display: none;
}

.sidebar__category-wrap {

  &.sidebar__category-wrap--open {

    .sidebar__category-icon {
      transform: rotate(90deg);
    }
  }
}

.sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  background: $color-red;


}

@media screen and (min-width: 768px) {
  .sidebar-collapse-wrapper {
    &:hover {

      .sidebar__category {
        width: 200px !important;
        @media screen and (max-width: 1500px) {
          width: 220px !important;
        }
      }
      .sidebar__submenu-wrap {
        display: inherit !important;
      }
    }
  }

  .sidebar {
    background: $color-accent;

    &.sidebar--no-desktop {

      &.sidebar--show {
        transform: translateX(0);
      }

      .sidebar__wrapper--mobile {
        display: block;
      }
    }

    &.sidebar--collapse {
      width: 70px;
      overflow: visible;

      @media screen and (max-width: 1500px) {
        width: 65px;
      }

      .sidebar__scroll, .sidebar__content {
        width: 70px;
        overflow: visible !important;
        @media screen and (max-width: 1500px) {
          width: 65px;
        }
      }

      .sidebar__submenu {
        padding: 0 0 15px 0;
      }

      .sidebar__category-wrap {

        &:hover {

          .sidebar__category {
            width: 200px;

            @media screen and (max-width: 1500px) {
              width: 220px;
            }

            &:before {
              opacity: 1;
            }
          }
        }
      }

      .sidebar__submenu-wrap {
        position: absolute;
        width: 0;
        display: none;
        left: 70px;
        @media screen and (max-width: 1500px) {
          left: 65px;
        }

        .sidebar__link {
          width: 170px;
          @media screen and (max-width: 1500px) {
            width: 155px;
          }

          p {
            position: relative;
            animation: none;

          }
        }

        .sidebar__submenu-wrap {
          position: relative;
        }      }

      .sidebar__link {
        overflow: hidden;
        width: 70px;
        background: $color-accent;
        padding: 21px 24px;

        @media screen and (max-width: 1500px) {
          width: 65px;
        }

        p {
          position: absolute;
          width: 160px;
          left: 70px;
        }

        &:hover {
          background: $color-accent-dark !important;
        }
      }

      .sidebar__link-active {
        .sidebar__link {
          background: $color-accent-dark !important;
        }
      }

      .sidebar__help-content  {
        a {
          font-size: 12px;
        }
      
        span {
          font-size: 10px;
        }
      }

      .sidebar__category-icon {
        opacity: 0;
        transition: opacity 0s;
      }

    }
  }

  .sidebar__wrapper--desktop {
    display: block;
  }

  .sidebar__wrapper--mobile {
    display: none;
  }
}

@media (max-width: 768px) {

  .sidebar {
    display: none;
    position: fixed;
    top: 65px;
    padding-top: 0;
    z-index: 101;
  }

  .sidebar__content {
    padding-top: 0;
  }
}

@media screen and (min-width: 1300px) {

  .sidebar.sidebar--no-desktop {
    transform: translateX(0);
    display: none;
  }
}

@media screen and (max-width: 1299px) {

  .sidebar.sidebar--no-desktop {
    display: none;
  }

  .sidebar.sidebar--no-desktop.sidebar--show {
    display: block;
  }
}
