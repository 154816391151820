

$color-accent2: #5F4C4E;
$color-accent2-hover: darken($color-accent2, 10%);

$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;
$color-light-gray: #d8dfe9;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);



$color-accent: #163115;
$color-accent-dark: darken($color-accent, 10%);
$color-accent-hover: darken($color-accent, 10%);
$color-transparent: transparent;

$color-content: #4D4F5C;
$color-content2: #252322;

// Form Color
$color-input-border: #E9E9F0;
$color-input-placeholder: #CECFD0;
$color-checkbox-border: #808495;
$color-radio-border: #D6D6D7;
$color-selectbox-arrow: #A4AFB7;
$color-icon: #5F4C4E;

// Button Color
$color-button-primary: #005389;
$color-button-primary-border: #4F4D5C;

// Login Color
$color-login-tab: #05A6A642;

// Register Color
$color-register-title: #2B284B;
$color-register-form-label: #43425D;

// Topbar
$color-topbar-icon: #4D565C;
$color-topbar-language-icon: #BCBCCB;
$color-topbar-divider: #F1F1F3;
$color-menu-left-border: #EBEBF2;

// Sidebar
$color-collapse-button: #0596A6;
$color-sidebar-link-left: #43425D;
$color-sidebar-link-hover: #0596A6;

// Dashboard
$dashboard-content-color: #778290;

