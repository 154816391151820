.card {
  .card-title {
    font-size: 15px;
    border-bottom: solid 1px black;
    text-align: center;
  }
  // .vertical-timeline::before {
  //   background-color: rgb(238 238 238);
  // }
  // .vertical-timeline {
  //   // max-width: 1000px;
  //   width: 100%;
  //   max-width: 100%;
  // }
  // .vertical-timeline-element-icon{
  //   width: 200px;
  //   border: none;
  //   border-radius: 0;
  //   box-shadow: none !important;
  // }
  // .vertical-timeline::before {
  //   left: 100px;
  // }
  .vertical-timeline:before {
    background-color: #eee;
}
}
#jobMoveFlowCard {
  .vertical-timeline::before {
    background-color: rgb(238 238 238);
  }
  .vertical-timeline {
    // max-width: 1000px;
    width: 100%;
    max-width: 100%;
  }
  .vertical-timeline-element-icon{
    width: 200px;
    border: none;
    border-radius: 0;
    box-shadow: none !important;
  }
  .vertical-timeline::before {
    left: 100px;
  }
}