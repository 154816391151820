//variables
@import 'variable.scss';

//layout
@import 'layout.scss';

//topbar
@import 'topbar.scss';

//sidebar
@import 'sidebar.scss';

// Icons
@import 'font-awesome/fontawesome';
@import 'font-awesome/regular';
@import 'font-awesome/brands';
@import 'font-awesome/solid';

//login
@import 'login.scss';

//custom
@import 'custom.scss';

//cards
@import 'card.scss';

// button
@import 'button.scss';

//components
@import 'react-datepicker/dist/react-datepicker.css';

// loading spinner

@import 'loadingSpinner.scss';

