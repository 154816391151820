#formFooter {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 15px;
    text-align: center;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
}

.btn-login {
    background-color: #007c85;
    border: 1px solid transparent;
    color: #fff;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 5px 5px 5px 5px;
    margin: 10px 0px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

    .btn-login:hover {
    background-color: #007c85;
    }
    .btn-register {
        padding: 8px 60px;
        color: #000 !important;
        background-color: #E9E8EE;
    }
    .btn-register:hover {
        padding: 8px 60px;
        background-color: #E9E8EE;
    }

    .btn-register .btn-login:hover {
    background-color: #f6f6f6;
    }
.loginput {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 1rem;
    border: 2px solid #f6f6f6;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
}

    .loginput:focus {
        background-color: #007c8540;
        border-bottom: 2px solid #007c8540;
;
    }

.loginh2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    margin: 40px 8px 10px 8px;
}

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.3s;
    -moz-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

    .fadeIn.first {
        -webkit-animation-delay: 0.2s;
        -moz-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }

    .fadeIn.second {
        -webkit-animation-delay: 0.3s;
        -moz-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }

    .fadeIn.third {
        -webkit-animation-delay: 0.4s;
        -moz-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }

    .fadeIn.fourth {
        -webkit-animation-delay: 0.5s;
        -moz-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
    display: block;
    left: 0;
    bottom: 2px;
    width: 0;
    height: 1px;
    background-color: #fff;
    content: "";
    transition: width 0.2s;
}

.underlineHover:hover {
    color: #fff;
}

    .underlineHover:hover:after {
        width: 100%;
    }

*:focus {
    outline: none;
}
