.topbar {
    width: 100%;
    position: fixed;
    top: 0;
    height: 100px;
    z-index: 101;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    background-color: white;
  }
  @media screen and (max-width: 768px) {
    .topbar{
      bottom: 0 !important;
      top: auto;

    }
    
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  .topbar__wrapper {
    position: relative;
    display: flex;
    height: 100%;
  }
  
  .topbar__button {
    width: 70px;
    height: 65px;
    display: flex;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: 0.3s;

   
    &:focus {
      outline: none;
    }
  
    &.topbar__button--desktop {
      display: none;
      
    }
   
  }
  
  .topbar__button-icon {
    margin: auto;
    transition: all 0.3s;
    width: 21px;
  }
  
  .topbar__logo {
    width: 250px;
    height: 33px;
    margin: auto 0;
    margin-left: 25px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: left;
    background-size: contain;
    display: none;
  
    @media screen and (max-width: 400px) {
      display: none;
    }
  
    @media screen and (min-width: 768px) {
      display: block;
    }
    @media screen and (max-width: 768px) {
      margin-left: 5px;
    }
    @media screen and (max-width: 576px) {
      width: 190px;
      height: 23px;
    }
  }
  
  .topbar__logo_tr {
    width: 250px;
    height: 33px;
    margin: auto 0;
    margin-left: 25px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: left;
    background-size: contain;
  
    @media screen and (max-width: 400px) {
      display: none;
    }
  
    @media screen and (min-width: 768px) {
      display: block;
    }
    @media screen and (max-width: 768px) {
      margin-left: 5px;
    }
    @media screen and (max-width: 576px) {
      width: 190px;
      height: 23px;
    }
  }
  
  .topbar__right {
    position: absolute;
    right: 0;
    display: flex;
    height: 100%;
    margin-right: 15px;
    p{
      align-self: center;
    }
  }
  
  .topbar__left {
    position: absolute;
    left: 0;
    display: flex;
    height: 100%;
    // width: 500px;
& img {
  @media screen and (max-width: 768px) {
    width: 120px;
  }
}
    .topbar__left-collapse-button {
      width: 200px;
      background: $color-accent;
      transition: width 0.3s;
      @media screen and (max-width: 1500px) {
        width: 220px;
      }
      @media screen and (max-width: 768px) {
        width: auto;
        background: #fff;
      }
    }
  }
  
  .dashboard.applicationsDetail.container{
    .topbar__right, .topbar__left{
    height: auto;
    }
    .card-body.tableWrapper{
      @media screen and (max-width:767px) {
        padding: 5vw;
      }
    }
  }
  
  .topbar__avatar {
    height: 100%;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 0;
    border: none;
    transition: all 0.3s;
    box-shadow: none;
    background-color: transparent;
  
    &:focus {
      outline: none;
    }
  
    &:before {
      display: none;
    }
  }
  
  .topbar__avatar-img, .topbar__avatar-name, .topbar__icon {
    margin: auto 0;
  }
  
  .topbar__avatar-img {
    border-radius: 50%;
    height: 36px;
    width: 36px;
  }
  
  .topbar__avatar-name {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 15px;
    color: $color-content !important;
    line-height: 18px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
  
    @media screen and (max-width: 1500px) {
      font-size: 13px;
    }
  }
  
  .topbar__avatar-name_mobile {
    display: none;
  
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  
  .topbar__icon {
    margin-left: 6px;
    height: 24px;
    margin-top: auto;
    fill: $color-selectbox-arrow;
  }
  .topbar__icon-language {
    color: $color-topbar-language-icon !important;
  }
  .topbar__menu {
    width: 200px;
    border-radius: 0;
    border: none;
    // padding: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
    margin-top: 0;
  
    @media screen and (max-width: 576px) {
      width: 170px;
    }
  
    button {
      padding: 0;
    }
  
    *:focus {
      outline: none;
    }
  }
  
  .topbar__menu-wrap {
    width: max-content;
    max-width: 250px;
    min-width: 150px;
    z-index: 101;
    position: absolute;
    background-color: white;
    opacity: 1;
    // padding: 0 10px;
    // min-width: 220px;
    right: 0;
    top: 70px;
    &:after {
      position: absolute;
      right: 21px;
      top: -4px;
      width: 0;
      height: 0;
      content: '';
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 4px solid #fff;
  
      @media screen and (max-width: 576px) {
        right: 50px;
      }
    }
    @media screen and (max-width: 1500px) {
      min-width: 140px;
    }
    
  }
  
  .topbar__link {
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    padding: 14px 20px;
    transition: all 0.3s;
    // height: 32px;
    width: 100%;
    justify-content: center;
    position: relative;
    cursor: pointer;
    color: $color-content;
  
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 2px;
      background: $color-accent;
      opacity: 0;
      transition: all 0.3s;
    }
  
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
  
  .topbar__link-sign-out {
    .topbar__link-title {
      font-weight: 600;
    }
  }
  
  .topbar__link-title {
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0;
    font-size: 15px;
    line-height: 16px;
    color: $color-content !important;
  
    @media screen and (max-width: 1500px) {
      font-size: 13px;
    }
  }
  
  .topbar__link-icon {
    font-weight: 600;
    margin-right: 10px;
    font-size: 16px;
    line-height: 16px;
    color: $color-topbar-icon;
    @media screen and (max-width: 1500px) {
      font-size: 14px;
      line-height: 14px;
    }
  }
  
  .topbar__menu-divider {
    border-top: 1px solid $color-topbar-divider;
  }
  
  .topbar__profile {
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 60px;
    position: relative;
    @media screen and (max-width: 992px) {
      margin-right: 0;
    }
  }
  
  .topbar__collapse {
    position: relative;
    display: none;
  
    &.topbar__collapse--language {
      min-width: 70px;
      display: block;
  
  
      & > button {
        padding: 0 4px;
        width: 100%;
        justify-content: center;
      }
    }
  
    @media screen and (min-width: 300px) {
      display: block;
    }
    .topbar__btn:hover{
      background-color: transparent !important;
    }
  }
  
  .topbar__collapse-content {
    width: 270px;
    position: absolute;
    right: 0;
    bottom: 8px;
    transform: translateY(100%);
    box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
    z-index: 101;
  
    &.topbar__collapse-content--language {
      max-width: 100px;
      // padding: 10px 0;
      width: 100%;
      &:after {
        position: absolute;
        right: 17px;
        top: -3px;
        width: 0;
        height: 0;
        content: '';
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 3px solid #fff;
      }
    }
  
    @media screen and (max-width: 768px) {
      left: 50%;
      transform: translate(-50%, 100%);
    }
  
    @media screen and (min-width: 520px) {
      width: 330px;
    }
  }
  .topbar__collapse-content-filter{
    @media screen and (max-width: 768px) {
      transform: translate(-90%, 100%);
    }
  }
  
  .topbar__language-btn {
    padding: 4px 15px;
    width: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
    text-align: left;
    font-size: 13px;
    line-height: 16px;
    transition: 0.3s;
  
    &:hover {
      color: $color-accent;
    }
  }
  
  .topbar__language-btn-active {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: $color-accent;
    opacity: 0;
    transition: all 0.3s;
  }
  
  .topbar__language-btn-title {
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    font-size: 13px;
    align-items: center;
    margin: auto 0;
    justify-content: center;
    color: $color-content !important;
  
    &:not(:last-child) {
      margin-right: 5px;
    }
  
    @media screen and (max-width: 1500px) {
      font-size: 11px;
    }
  
    img {
      height: 11px;
      width: 16px;
      margin-right: 4px;
    }
  }
  
  .topbar__back {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: transparent;
    border: none;
    cursor: default !important;
  }
  
  .topbar__collapse-title-wrap {
    padding: 20px 15px 15px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
  }
  
  .topbar__collapse-item {
    padding: 12px 55px 12px 70px;
    display: flex;
    position: relative;
    height: 62px;
    align-items: center;
    flex-wrap: wrap;

  }
  
  .topbar__collapse-img-wrap {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    left: 15px;
  }
  
  .topbar__collapse-message {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray;
  
    &.topbar__collapse-message--mail {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  
  .topbar__collapse-name {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: $color-accent;
  }
  
  .topbar__collapse-date {
    position: absolute;
    top: 12px;
    right: 15px;
    font-size: 10px;
    color: $color-gray;
    margin-top: 2px;
  }
  
  .topbar__collapse-link {
    display: block;
    padding: 10px;
    text-transform: uppercase;
    color: $color-accent;
    transition: 0.3s;
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
  
    &:hover {
      color: $color-accent-hover;
    }
  }
  
  .topbar__collapse-title {
    font-size: 14px;
    line-height: 16px;
  }
  
  .topbar__collapse-button {
    color: #70bbfde6;
    border: none;
    padding: 0;
    text-align: right;
    font-size: 12px;
    line-height: 16px;
    transition: 0.3s;
    background: transparent;
  
    &:hover {
      color: $color-accent;
    }
  }
  
  .topbar__btn {
    font-size: 18px;
    height: 100%;
    padding: 0 10px;
    cursor: pointer;
    position: relative;
    display: flex;
    border: none;
    background: transparent;
    transition: all 0.3s;
  
    &:before {
      content: "";
      height: 50%;
      width: 1px;
      background: $color-menu-left-border;
      position: absolute;
      left: 0;
      top: 25%;
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
    }
  
    svg {
      margin: auto 0;
      height: 24px;
      height: 24px;
      fill: $color-selectbox-arrow;
    }
  
    &.topbar__btn--new {
  
      .topbar__btn-new-label {
        position: absolute;
        right: 9px;
        top: 20px;
  
        & > div {
          position: relative;
  
          &:before {
            background-color: rgba(224, 83, 111, 0.2);
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            animation: beforePulse 1.5s infinite;
          }
  
          &:after {
            height: 7px;
            width: 7px;
            background-color: #e0536f;
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  
    @keyframes beforePulse {
      from {
        width: 7px;
        height: 7px;
      }
      25% {
        width: 13px;
        height: 13px;
      }
      to {
        width: 7px;
        height: 7px;
      }
    }
  }
  
  .topbar__nav {
    width: 100%;
    display: none;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .topbar .topbar__nav-dropdown-toggle {
    height: 60px;
    background: transparent;
    border-radius: 0;
    border: none;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  
    &:before {
      display: none;
    }
  
    svg {
      fill: $color-additional;
      margin-left: 3px;
      height: 16px;
      width: 16px;
      margin-right: 0;
    }
  }
  
  .topbar__nav-dropdown-menu {
    width: 200px;
    border-top: 2px solid $color-accent;
  
    button {
      padding: 0;
    }
  }
  
  .topbar__link-badge {
    width: 26px;
    height: 14px;
    background-color: $color-red;
    font-size: 8px;
    font-weight: 400;
    padding: 2px;
    margin-left: 5px;
    line-height: 9px;
    position: relative;
    text-transform: uppercase;
    border-radius: 7px;
  
    span {
      position: absolute;
      left: 0;
      top: 3px;
      width: 26px;
      text-align: center;
    }
  }
  
  .topbar__nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 10px 25px;
    transition: 0.3s;
    font-size: 14px;

  }
  
  .topbar__category-wrap {
    position: relative;
  
    &:hover {
  
      .topbar__submenu {
        opacity: 1;
        width: auto;
        height: auto;
      }
    }
  }
  
  .topbar__category-icon {
    position: absolute;
    right: 20px;
    font-size: 10px;
    line-height: 14px;
  
  }
  
  .topbar__submenu {
    position: absolute;
    right: 1px;
    top: 0;
    transform: translateX(100%);
    transition: 0.3s;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;

  }
  
  .topbar__search {
    display: none;
    margin: auto 0;
    padding: 0;
    position: relative;
  }
  
  .topbar__search-field {
    width: 0;
    transition: all 0.3s;
    opacity: 0;
    margin: auto auto auto 0;
    border: none;
    border-radius: 13px;
    height: 26px;
    padding-left: 10px;
    padding-right: 46px;

    &.topbar__search-field--open {
      width: 200px;
      opacity: 1;
      margin-left: 10px;
  
      & + button {
        right: 10px;
      }
    }
  
    &:focus {
      outline: none;
    }
  }
  
  .topbar__search-btn {
    height: 26px;
    width: 26px;
    border-radius: 13px;
    border: none;
    padding: 0;
    background: transparent;
    position: absolute;
    right: 0;
  
  }
  
  @media screen and (min-width: 576px) {
    .topbar__menu {
      width: 100%;
      left: 0 !important;
    }
  }

  @media screen and (min-width: 768px) {
  
    .topbar__button {
      &.topbar__button--desktop {
        display: block;
        width: 65px;
      }
  
      &.topbar__button--mobile {
        display: none;
      }
    }
  
    .topbar.topbar--navigation {
  
      .topbar__button.topbar__button--mobile {
        display: block;
      }
    }
  }
  @media screen and (max-width: 1501px) {
  
    .topbar__button {
      &.topbar__button--desktop {
        width: 65px;
      }
  }
}
@media screen and (min-width: 1501px) {

  .topbar__button {
    &.topbar__button--desktop {
      width: 70px;
    }
}
}
  
  @media screen and (min-width: 768px) {
  
    .topbar__search {
      display: flex;
    }
  }
  
  @media screen and (min-width: 992px) {
  
    .topbar__nav {
      display: flex;
    }
  
    .topbar.topbar--navigation {
      .topbar__logo {
        margin-left: 15px;
        display: block;
      }
  
      .topbar__button.topbar__button--mobile {
        display: none;
      }
      .topbar__profile {
        margin-left: 0;
      }
    }
  }
  
  @media screen and (min-width: 1200px) {
  
    .topbar.topbar--navigation {
  
      .topbar__avatar-name {
        display: block;
      }
    }
  }
  
  @media screen and (min-width: 1580px) {
  
    .topbar__nav-dropdown-toggle, .topbar__nav-link {
      width: 200px;
    }
  }