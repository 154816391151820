.container {
    padding-right: 20px;
    padding-left: 25px;
  height: 100%;
    @media screen and (min-width: 768px) {
      width: 100%;
      max-width: 2100px;
    }
    @media screen and (max-width: 768px) {
      max-width: 100% !important; 
      padding-left: 20px;
    }
  }
  
  .container__wrap {
    padding-top: 120px;
    min-height: 100vh;
    background-color: #fff;
  }
  
  .layout {
  
    &.layout--collapse {
      .topbar__left-collapse-button {
        width: auto;
      }
  
      & + .container__wrap {
        padding-left: 0;
  
        @media screen and (min-width: 768px) {
          padding-left: 65px;
        }
      }
    }
  
    &.layout--top-navigation{
  
      & + .container__wrap{
  
        @media screen and (min-width: 768px) {
          padding-left: 0;
        }
      }
    }
  }
  
  