html, body {
   font-family: 'Titillium Web', sans-serif;
   color: #343335;
}
#homepage {
  font-family: Poppins,sans-serif;
}
.row {
   margin-right: 0;
   margin-left: 0;
}
.shadow {
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
     0px 2px 2px 0px rgb(0 0 0 / 14%),
     0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.form__form-group-label {
     font-size: 15px;
     margin-top: 8px;
}
.form__form-group{
     margin-top: 0.5rem;
}

div.container__wrap > div.card {
border: none;
}

//primary 
.btn-custom-primary {
     color: #fff !important;
    background-color: #334192 !important;
    border-color: #334192;
}
.btn-outline-custom-primary {
     color: #000;
    border-color: #334192;
}
.btn-custom-primary:hover {
     color: #000;
     background-color: #334192c0;
     border-color: #334192c0;
 }
 .bg-custom-primary {
     color: #fff !important;
     background-color: #334192 !important;
 }

//seconday
 .btn-custom-secondary {
     color: #000;
    background-color: #f4eaef;
    border-color: #f4eaef;
}
.btn-outline-custom-secondary {
    color: #000;
   border-color: #f4eaef;
}
.btn-custom-secondary:hover {
     color: #000;
     background-color: #f4eaefc0;
     border-color:#f4eaefc0;
 }
 .bg-custom-secondary {
    color: #000 !important;
    background-color: #f4eaef !important;
 }


 //success
 .btn-custom-success, .MuiChip-colorPrimary {
    color: #fff !important;
   background-color: #788d0c !important;
   border-color: #788d0c;
}
.btn-outline-custom-success {
   color: #fff;
  border-color: #788d0c;
}
.btn-custom-success:hover {
    color: #fff;
    background-color: #4b580a !important;
    border-color: #788d0cc0;
}
.bg-custom-success {
   color: #fff !important;
   background-color: #788d0c !important;
}
.bg-custom-success-2 {
   color: #fff !important;
   background-color: rgb(0, 124, 133) !important;

}

.bg-success {
   color: #fff !important;
}


//danger
.btn-custom-danger {
   color: #fff;
  background-color: #ab243e;
  border-color: #ab243e;
}
.btn-outline-custom-danger {
   color: #fff;
  border-color: #ab243e;
}
.btn-custom-danger:hover {
   color: #fff;
   background-color: #7a0920;
   border-color: #ab243ec0;
}
.bg-custom-danger {
   color: #fff !important;
   background-color: #ab243e !important;
}


.btn-custom-warning {
   color: #fff;
  background-color: #f0b310;
  border-color: #f0b310;
}
.btn-outline-custom-warning {
   color: #000;
  border-color: #f0b310;
}
.btn-custom-warning:hover {
   color: #fff;
   background-color: #b98b0d;
   border-color: #f0b310c0;
}
.bg-custom-warning {
   color: #fff !important;
   background-color: #f0b310 !important;
}


//info
.btn-custom-info {
   color: #fff;
  background-color: #7f96c4;
  border-color: #7f96c4;
}
.btn-outline-custom-info {
   color: #000;
  border-color: #7f96c4;
}
.btn-custom-info:hover, .btn-custom-info:active, .btn-custom-info:focus {
   color: #fff !important;;
   background-color: #7f96c4c0 !important;
   border-color: #7f96c4c0 !important;;
}
.bg-custom-info {
   color: #fff !important;
   background-color: #7f96c4 !important;
}

.bg-ghostwhite {
   background-color: ghostwhite !important;
  }
  .bg-ghostwhite-light {
      background-color: #f8f8ff66 !important;
     }

   
 
 .input_class {
     width: 100%;
     font-size: 14px;
     height: 24px;
     border: 1px solid #e9e9f0;
 }
 .input_class:not([type=textarea]){
      height: auto;
 }
 
 .input_class:active, .input_class:focus{
     border: 1px solid $color-accent;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.modal__close-btn {
  position: absolute !important;
  top: 15px;
  right: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
}

.react-datepicker__input-container > input {
     width: 100%;
}
.form__form-group-error{
     font-size: 14px;
     color: #df4759;
}
.modal-content {
   //   padding: 2rem;
}
input:not([type=radio], [type=checkbox]) { 
     font-size: 13px;
     width: 100%;
}
input[type="radio"] {
     margin-right: 0.5rem;
}
.MuiBadge-badge {
min-width: 13px !important;
height: 13px !important;
padding: 0 !important;
right: 5px !important;
}


.btn-light:focus, .btn-primary:focus, .btn-secondary:focus, .btn-danger:focus, .btn-warning:focus, .btn-info:focus {
box-shadow: 0 0 0 0.1rem rgb(211 212 213 / 50%);
}

.centerize_div {
   display: flex;
   justify-content: center;
   flex-direction: column;
   & > img {
     margin-left: auto;
     margin-right: auto;
     width: auto;
   }
   //width ve height dışardan verilmeli, aynı verilen değer içerdeki img e ise max-width, max-height olarak verilmeli
 }
 .carousel {
   .carousel-status {
      font-size: 15px;
      color: 'white'
   }
 }
.tableChip{
  height: 22px !important;
}
.MuiChip-labelSmall{
  padding-top: '2px !important'
}
 .MuiChip-label.tableChip{
   max-width: 100px
 }
 .MuiChip-label.tableChip:hover {
    overflow: inherit;
    white-space: pre-line;
 }
 .MuiChip-sizeSmall.tableChip:hover {
   height: auto;
   min-height: 22px;
}
p{
   margin-bottom: 0.5rem;
}
.modal-open{
   padding: 0 !important;
}

.MuiTableCell-root { // metarial ui table head css bug fix
   display: table-cell !important;
}
.recharts-legend-item { font-size: 0.75rem; }
.dropdown-menu{
   min-width: 15rem;
}
.slider-wrapper {
   min-height: 100px !important;
}
.MuiTableCell-alignLeft > .MuiChip-sizeSmall {
max-width: 250px;
}
.MuiToolbar-root {
   flex-wrap: wrap;
}
.h-100 {
   height: 100% !important;
}
.MuiBox-root{
   padding-left: 0 !important;
   padding-right: 0 !important;
}

.MuiAccordionSummary-content{
   margin: 0 !important;
}
.navLinkResponsive{
   font-size: 18px;
}
.NavLinkIconResponsive{
   font-size: 28px;
}
@media screen and (max-width: 1441px) {
   .responsive-font-size{
      font-size: 14px;
   }
 }
 @media screen and (max-width: 1200px) {
    .responsive-font-size{
       font-size: 12px;
    }
  }

  @media screen and (max-width: 768px) {
   .navLinkResponsive{
      font-size: 16px;
   }
   .NavLinkIconResponsive{
      font-size: 20px;
   }
   .container__wrap{
      padding-top: 1rem !important; ;
   }
}

@media screen and (max-width: 500px) {
   .navLinkResponsive{
      font-size: 12px;
      width: 60px;
   }
   .NavLinkIconResponsive{
      font-size: 18px;
   }
   .topbar {
      height: 60px !important;
   }
   .topbar__avatar-name {
      margin-left: 0 !important;
      margin-right: 0 !important;
   }
   .topbar__menu-wrap{
      top: -135px !important;
      right: -20px !important;
   }
 
}
button:active, button:active:hover, button:focus {
   color: inherit !important;
   background-color: white !important;
   border-color: #000 !important;
}
.btn:focus {
outline: 0 !important;
box-shadow: none !important;
}

$content-width: calc(70vw - 84px);
$margin: 20px;
$spacing: 20px;
$bdrs: 6px;
$circle-size: 40px;
$icon-size: 32px;
$bdrs-icon: 100%;

$color1: #9251ac;
$color2: #f6a4ec;

$color3: #87bbfe;
$color4: #555ac0;

$color5: #24b47e;
$color6: #aff1b6;
* {
  box-sizing: border-box;
}
.timeline {
  display: flex;
  flex-direction: column;
  margin: $margin auto;
  position: relative;

  &__event {
    margin-bottom: $spacing;
    position: relative;
    display: flex;
    margin: $spacing 0;
    border-radius: $bdrs;
    align-self: center;
    width: 70vw;

    &:nth-child(2n + 1) {
      flex-direction: row-reverse;
      .timeline__event__date {
        border-radius: 0 $bdrs $bdrs 0;
      }
      .timeline__event__content {
        border-radius: $bdrs 0 0 $bdrs;
      }

      .timeline__event__icon {
        &:before {
          content: "";
          width: 2px;
          height: 100%;
          background: $color2;
          position: absolute;
          top: 0%;
          left: 50%;
          right: auto;
          z-index: -1;
          transform: translateX(-50%);
          animation: fillTop 2s forwards 4s ease-in-out;
        }
        &:after {
          content: "";
          width: 100%;
          height: 2px;
          background: $color2;
          position: absolute;
          right: 0;
          z-index: -1;
          top: 50%;
          left: auto;
          transform: translateY(-50%);
          animation: fillLeft 2s forwards 4s ease-in-out;
        }
      }
    }

    &__title {
      font-size: 1.2rem;
      line-height: 1.4;
      text-transform: uppercase;

      font-weight: 600;
      color: $color1;
      letter-spacing: 1.5px;
    }
    &__content {
      padding: $spacing;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      background: #fff;
      width: $content-width;
      border-radius: 0 $bdrs $bdrs 0;
    }
    &__date {
      color: $color2;
      font-size: 1.5rem;
      font-weight: 600;
      background: $color1;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 0 $spacing;
      border-radius: $bdrs 0 0 $bdrs;
    }
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color1;
      padding: $spacing;
      align-self: center;
      margin: 0 $spacing;
      background: $color2;
      border-radius: $bdrs-icon;
      width: $circle-size;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      padding: 40px;
      height: $circle-size;
      position: relative;

      i {
        font-size: $icon-size;
      }

      &:before {
        content: "";
        width: 2px;
        height: 100%;
        background: $color2;
        position: absolute;
        top: 0%;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);
        animation: fillTop 2s forwards 4s ease-in-out;
      }
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: $color2;
        position: absolute;
        left: 0%;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        animation: fillLeftOdd 2s forwards 4s ease-in-out;
      }
    }
    &__description {
      flex-basis: 60%;
    }

    &--type2 {
      &:after {
        background: $color4;
      }
      .timeline__event__date {
        color: $color3;
        background: $color4;
      }

      &:nth-child(2n + 1) {
        .timeline__event__icon {
          &:before,
          &:after {
            background: $color3;
          }
        }
      }

      .timeline__event__icon {
        background: $color3;
        color: $color4;
        &:before,
        &:after {
          background: $color3;
        }
      }
      .timeline__event__title {
        color: $color4;
      }
      .timeline__event__title {
      }
    }

    &--type3 {
      &:after {
        background: $color5;
      }
      .timeline__event__date {
        color: $color6;
        background-color: $color5;
      }

      &:nth-child(2n + 1) {
        .timeline__event__icon {
          &:before,
          &:after {
            background: $color6;
          }
        }
      }

      .timeline__event__icon {
        background: $color6;
        color: $color5;
        &:before,
        &:after {
          background: $color6;
        }
      }
      .timeline__event__title {
        color: $color5;
      }
      .timeline__event__title {
      }
    }
    &:last-child {
      .timeline__event__icon {
        &:before {
          content: none;
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
    align-self: center;
    &__content {
      width: 100%;
    }

    &__icon {
      border-radius: $bdrs $bdrs 0 0;
      width: 100%;
      margin: 0;
      box-shadow: none;

      &:before,
      &:after {
        display: none;
      }
    }
    &__date {
      border-radius: 0;
      padding: $spacing;
    }

    &:nth-child(2n + 1) {
      flex-direction: column;
      align-self: center;

      .timeline__event__date {
        border-radius: 0;
        padding: $spacing;
      }

      .timeline__event__icon {
        border-radius: $bdrs $bdrs 0 0;
        margin: 0;
      }
    }
  }
}

@keyframes fillLeft {
  100% {
    right: 100%;
  }
}

@keyframes fillTop {
  100% {
    top: 100%;
  }
}

@keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}
#menu {
  display: block;
  position: relative;
  z-index: 99;
}
#menu li {
  display: flex;
  justify-content: space-between;
  float: left;
  border-left: 2px solid #FFF;
}
#menu li:first-child {
  display: block;
  float: left;
  border-left: none;
}

#menu li a:hover {
  background: #fff;
  color: #2c343b;
  width: 100%;
}

#menu li a {
  display: block;
  position: relative;
  float: left;
  padding: 0px 80px;
  font-family: 'Titillium Web';
  font-size: 13px;
  line-height: 43px;
  text-decoration: none;
  color: #f5f5f5;
}
.text-primary {
  color: #334192;
}
.card-img,
.card-img-bottom,
.card-img-top {
  flex-shrink: 0;
  width: 90px;
height: 120px;
margin: auto;
}
.accredidated_hospitals{
  width: 300px;
  height: 300px;
  
  margin: auto;
}
.card-social {
  position: absolute;
  width: 100%;
  height: 0;
  top: 100%;
  left: 0;
  opacity: 0;
  transition: 0.5s;
  z-index: 1;
}
.card:hover .card-social {
  top: 0;
  height: calc(100% - 100px);
  opacity: 1;
  background: rgba(0, 0, 0, 0.3);
}
.card-body {
  position: relative;
  z-index: 2;
}
.form__form-group-character {
  float: right;
  color: #334192;
}
.material-table__wrap > div > div > div >div >div {
  min-height: 70vh;
}