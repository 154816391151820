.loading_wrapper{
  display: grid;
  .loading_logo_wrapper, .loading_animation_wrapper{
    display: grid;
    grid-row: 1;
    grid-column: 1;
  }
  .loading_animation_wrapper{
    width: 100%;
    height: 100%;
    .loading_spinner{
      width: 5% !important;
      height: auto !important;
      min-width: 4rem !important;
      min-height: auto !important;
      z-index: 30;
      margin: auto;
    }
  }
  .loading_logo_wrapper{
    .loading_logo{
      width: 3.5rem;
      height: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
      margin: auto;
    }
  }
}
.loading_placeholder{
  background-color: #F0F0F7;
  border-radius: 10px;
}